import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { CreateParkReviewMutation, ParkReview } from "API"
import { generateClient, GraphQLResult } from "aws-amplify/api"
import { useFormReducer } from "components/form/useFormReducer"
import { createParkReview, updateParkReview } from "graphql/mutations"
import { z } from "zod"
import './CreateReview.css'
import {Transition} from "../transition/Transition";

const FormSchema = z.object({
    title: z.string().refine((v) => v.length !== 0, "Title is required").refine((v) => v.length < 50, "Title must be less than 50 characters"),
    description: z.string().refine((v) => v.length !== 0, "Description is required").refine((v) => v.length < 2500, "Description is too long"),
    rating: z.number().max(5, "Rating must be no greater than 5").min(1, "Rating must be atleast 1")
})

type CreateReviewProps = {
    parkId: string,
    name: string,
    review?: ParkReview, //if a review is passed in then we will edit rather than create
    onClose: () => void
}


export const CreateReview = ({ parkId, name, review, onClose }: CreateReviewProps) => {
    const client = generateClient()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (title: string, description: string, rating: number) => {
        const createRes = (await client.graphql({
            query: review ? updateParkReview : createParkReview,
            variables: {
                input: {
                    id: review?.id,
                    parkId: parkId,
                    title: title,
                    description: description,
                    rating: rating
                },
            },
        })) as GraphQLResult<CreateParkReviewMutation>
    }

    const [state, dispatch, errors] = useFormReducer(
        {
            title: review?.title || "",
            description: review?.description || "",
            rating: review?.rating || 5
        },
        FormSchema,
    );

    return (
        <Dialog
            fullWidth={true}
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <DialogTitle>Write a review</DialogTitle>
            <DialogContent>
                <Typography component="div" variant="subtitle1" paddingBottom={'3rem'}>{name}</Typography>
                <Box>
                    <div className="CreateReview-form-container">
                        <div className="CreateReview-form-input">
                            <TextField disabled={loading} id="filled-basic" label="Title" variant="filled" value={state.title} onChange={(e) => dispatch({ type: "update", payload: { key: "title", value: e.target.value } })} />
                            {state.submitted && errors.title?.errors && <Typography component="label" variant="caption" color={'error'}>{errors.title.errors}</Typography>}
                        </div>
                        <div className="CreateReview-form-input">
                            <TextField disabled={loading} id="filled-basic" label="Description" variant="filled" value={state.description} multiline rows={6} onChange={(e) => dispatch({ type: "update", payload: { key: "description", value: e.target.value } })} />
                            {state.submitted && errors.description?.errors && <Typography component="label" variant="caption" color={'error'}>{errors.description.errors}</Typography>}
                        </div>
                        <div className="CreateReview-form-input">
                            <Typography component="legend">Rating {state.rating}/5</Typography>
                            {state.submitted && errors.rating?.errors && <Typography component="label" variant="caption" color={'error'}>{errors.rating.errors}</Typography>}
                            <Rating
                                disabled={loading}
                                name="simple-controlled"
                                size="large"
                                value={state.rating}
                                onChange={(event, newValue) => {
                                    dispatch({ type: "update", payload: { key: "rating", value: newValue || 1 } })
                                }}
                            />
                        </div>
                    </div>

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Close</Button>
                <Button disabled={loading} onClick={async () => {
                    dispatch({ type: "submit" })
                    console.log(errors)
                    if (Object.keys(errors).length === 0) {
                        setLoading(true)
                        await handleSubmit(state.title, state.description, state.rating)
                        onClose()
                    }
                }} variant="contained">Submit</Button>
            </DialogActions>
        </Dialog>
    )

}