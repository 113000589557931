import React, {useMemo, useState} from 'react';
import {Layer, MapLayerMouseEvent, Source} from 'react-map-gl'
import {useParams} from "react-router-dom";
import data from "../../data/geoJson.json";
import {DefaultMap} from "../map/DefaultMap";
import {dataLayer, dataLineLayer} from "../map/mapUtils";
import {z} from "zod";
import {ErrorType, FormActionType, FormType, useFormReducer} from "../form/useFormReducer";
import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import "./AddPoint.css"
import {getPointLabelOptions, ParkPoint, Point, PointIcon, PointMark, pointTypeToLabelOption} from "./PointMark";
import {Transition} from "../transition/Transition";
import Typography from "@mui/material/Typography";
import {createPointOfIntrest, updatePointOfIntrest} from "../../graphql/mutations";
import {generateClient, GraphQLResult} from "aws-amplify/api";
import {CreatePointOfIntrestMutation, PointOfIntrest} from "../../API";

const PointSchema = z.object({
  id: z.union([z.string(), z.undefined()]),
  name: z.string().max(50, "Name must be less than 50 characters"),
  description: z.string().max(2500, "Description is too long"),
  pointType: Point,
  point: z.object({longitude: z.number(), latitude: z.number()})
})

const FormSchema = z.object({
  items: z.array(PointSchema)
})

type FormSchemaType = z.infer<typeof FormSchema>
type PointType = z.infer<typeof PointSchema>

type Props = {
  onClose: () => void
  usersPoints: PointOfIntrest[]
}

export function AddPoint({onClose, usersPoints}: Props) {
  const client = generateClient()
  const {parkId} = useParams()
  const [addPointFormIndex, setAddPointFormIndex] = useState<number>()
  const [loading, setLoading] = useState(false)

  const parkData = useMemo(() => {
    // @ts-ignore
    return parkId ? data.features.find(feature => feature.properties.id === parkId) : undefined
  }, [parkId]);

  const [state, dispatch, errors] = useFormReducer({
    items: usersPoints.map(point => {
      console.log(point)
      return {
        id: point.id,
        point: {latitude: point.latitude, longitude: point.longitude},
        description: point.description,
        name: point.name,
        pointType: ParkPoint.parse(point.pointType),

      }
    })
  }, FormSchema);

  const handleSubmit = async (points: PointType[]) => {

    await Promise.all(points.flatMap(async point => {

      if(point.id) {
        return await client.graphql({
          query: updatePointOfIntrest, variables: {
            input: {
              id: point.id,
              parkId: parkId,
              name: point.name,
              description: point.description,
              longitude: point.point.longitude,
              latitude: point.point.latitude,
              pointType: point.pointType
            },
          },
        }) as GraphQLResult<CreatePointOfIntrestMutation>
      } else {
        return await client.graphql({
          query: createPointOfIntrest, variables: {
            input: {
              parkId: parkId,
              name: point.name,
              description: point.description,
              longitude: point.point.longitude,
              latitude: point.point.latitude,
              pointType: point.pointType
            },
          },
        }) as GraphQLResult<CreatePointOfIntrestMutation>
      }
    }))
  }

  const onClick = ({lngLat: {lat, lng}}: MapLayerMouseEvent) => {
    dispatch({
      type: "update-array", payload: {
        key: "items",
        type: "add",
        value: {point: {longitude: lng, latitude: lat}, name: "", description: "", pointType: "PARKING"}
      }
    })
    setAddPointFormIndex(state.items.length)
  }

  return <Dialog
    fullScreen={true}
    open={true}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <DialogTitle>Add a point of interest</DialogTitle>
    <DialogContent>
      <Box>
        <div className="AddPoint-form-container">
          {addPointFormIndex !== undefined && <AddPointForm
              onClose={() => setAddPointFormIndex(undefined)}
              onCancel={() => {
                dispatch({
                  type: "update-array", payload: {type: "delete", key: "items", index: addPointFormIndex}
                })
                setAddPointFormIndex(undefined)
              }}
              state={state}
              dispatch={dispatch}
              errors={errors}
              index={addPointFormIndex}
              genericPoint={parkId === undefined}
          />}
          <div className="AddPoint-form-input">
            <DefaultMap mapProps={{onClick, style: {width: "calc(100vw - 48px)", height: "80vh"}}}>
              {  /* @ts-ignore */}
              {parkData && (<Source type="geojson" data={parkData}>
                <Layer {...dataLineLayer}/>
                <Layer {...dataLayer}/>
              </Source>)}
              {state.items.flatMap((item, index) => <PointMark
                key={index}
                longitude={item.point.longitude}
                latitude={item.point.latitude}
                pointType={item.pointType}
                onDrag={(latitude, longitude) => dispatch({
                  type: "update-array", payload: {
                    index,
                    type: "update",
                    key: "items",
                    value: {type: "update", payload: {key: "point", value: {latitude, longitude}}}
                  }
                })}
                onClick={() => setAddPointFormIndex(index)}
              />)}
            </DefaultMap>
          </div>
        </div>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={onClose}>Close</Button>
      <Button
        disabled={loading}
        onClick={async () => {
          dispatch({type: "submit"})
          console.log(errors)
          if (Object.keys(errors).length === 0) {
            setLoading(true)
            await handleSubmit(state.items)
            onClose()
          }
        }}
        variant="contained"
      >
        Submit
      </Button>
    </DialogActions>
  </Dialog>
}


type AddPointFormProps = {
  onClose: () => void
  onCancel: () => void
  state: FormType<FormSchemaType>
  dispatch: (action: FormActionType<FormSchemaType>) => void
  errors: ErrorType<FormSchemaType>
  index: number
  genericPoint: boolean
}

function AddPointForm({onClose, onCancel, state, dispatch, errors, index, genericPoint}: AddPointFormProps) {
  return <Dialog
    fullScreen={true}
    open={true}
    TransitionComponent={Transition}
  >
    <DialogTitle>Add a point of interest</DialogTitle>
    <DialogContent>
      <Box>
        <div className="AddPoint-form-container">
          <div className="AddPoint-form-input">
            <TextField
              id="filled-basic"
              label="Title"
              variant="filled"
              value={state.items[index].name}
              onChange={(e) => dispatch({
                type: "update-array", payload: {
                  key: "items",
                  index,
                  type: "update",
                  value: {type: "update", payload: {key: "name", value: e.target.value}}
                }
              })}
            />
          </div>
          <div className="AddPoint-form-input">
            <TextField
              id="filled-basic"
              label="Description"
              variant="filled"
              value={state.items[index].description}
              multiline
              rows={6}
              onChange={(e) => dispatch({
                type: "update-array", payload: {
                  key: "items",
                  index,
                  type: "update",
                  value: {type: "update", payload: {key: "description", value: e.target.value}}
                }
              })}
            />
          </div>
          <div className="AddPoint-form-input">
            <Autocomplete
              defaultValue={pointTypeToLabelOption(state.items[index].pointType)}
              onChange={(_, newValue) => newValue && dispatch({
                type: "update-array", payload: {
                  key: "items",
                  index,
                  type: "update",
                  value: {type: "update", payload: {key: "pointType", value: newValue.id}}
                }
              })}
              renderInput={(params) => <TextField {...params} label="Search"/>}
              renderOption={(props, option) => (<Box
                {...props}
                component="li"
                key={option.id}
              >
                <PointIcon pointType={option.id}/>
                <Typography variant="body1" component="div" paddingLeft={"0.5rem"}>{option.label}</Typography>
              </Box>)}
              options={getPointLabelOptions(genericPoint)}
              className={"SearchPage-searchBar"}
            />
          </div>
        </div>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onClose} variant="contained">Save</Button>
    </DialogActions>
  </Dialog>
}