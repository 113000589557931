import {generateClient} from "aws-amplify/api";
import {userPool} from "../../userpool";
import {ListPointOfIntrestsQuery, PointOfIntrest} from "../../API";
import React, {useEffect, useMemo, useState} from "react";
import {listPointOfIntrests} from "../../graphql/queries";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {StyleFunction, SymbolLayer} from "mapbox-gl";
import {Layer, Source} from "react-map-gl";
import {useMapPointImages} from "../../components/point/useMapPointImages";
import {GenericPoint, ParkPoint, PointType} from "../../components/point/PointMark";

export function PointsOfInterest() {
  useMapPointImages()
  const user = userPool.getCurrentUser();
  const client = generateClient()
  const [points, setPoints] = useState<[] | PointOfIntrest[]>([])


  useEffect(() => {
    const pointsPromise = client.graphql({
      query: listPointOfIntrests, authMode: user ? 'userPool' : 'iam',
    }) as Promise<GraphQLResult<ListPointOfIntrestsQuery>>

    pointsPromise.then((res) => {
      const items = res.data?.listPointOfIntrests?.items ?? []
      setPoints(items.filter((item): item is PointOfIntrest => Boolean(item)))
    })
  }, [])

  const formatPointFeatures = (parkPoint: PointOfIntrest[]): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
    return {
      type: "FeatureCollection", features: parkPoint.map((point): GeoJSON.Feature => ({
        type: "Feature", properties: {pointType: point.pointType}, geometry: {
          coordinates: [point.longitude, point.latitude], type: "Point"
        }, id: point.id
      }))
    }}

  const pointFeatures = useMemo(() => {
    return formatPointFeatures(points)
  }, [points])


  const pointHaloColorStyleFunction: StyleFunction = {
    property: 'pointType',
    type: "categorical",
    stops: [["PARKING", '#81838a'], ["TOILET", '#7ba1eb'], ["RUBBISH", '#efce90'], ["DOG_WASH", '#7ba1eb']] satisfies [PointType, string][],
    default: '#000',
  }

  const pointImageStyleFunction: StyleFunction = {
    property: 'pointType',
    type: "categorical",
    stops: [["PARKING", 'parking'], ["TOILET", 'toilet'], ["RUBBISH", 'rubbish'], ["DOG_WASH", 'dog-wash']] satisfies [PointType, string][],
    default: 'circle',
  }

  const layerStyle: SymbolLayer = {
    id: 'point-symbol', type: 'symbol', minzoom: 10, layout: {
      "icon-image": pointImageStyleFunction, 'icon-size': 0.3,
    }, "paint": {
      "icon-color": '#F3F3F3', "icon-halo-color": pointHaloColorStyleFunction, "icon-halo-width": 5, "icon-halo-blur": 0
    }
  };

  return <>
    <Source id="park-points-of-interest" type="geojson" data={pointFeatures}>
      <Layer {...layerStyle}/>
    </Source>
  </>
}