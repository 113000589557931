import React, {useCallback, useEffect, useState} from 'react';
import {Layer, Source} from 'react-map-gl';
import './MapPage.css';
import {AppNavigator} from "components/AppNavigator";
import data from "data/geoJson.json";
import {createSearchParams, useNavigate} from "react-router-dom";
import {userPool} from "../../userpool";
import type mapboxgl from 'mapbox-gl'
import {DefaultMap} from "../../components/map/DefaultMap";
import {dataLayer, dataLineLayer} from "../../components/map/mapUtils";
import {PointsOfInterest} from "./PointsOfInterest";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import {Add} from "@mui/icons-material";
import {AddPoint} from "../../components/point/AddPoint";
import {ListPointOfIntrestsQuery, PointOfIntrest} from "../../API";
import {listPointOfIntrests} from "../../graphql/queries";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";


export function MapPage() {
  const navigate = useNavigate()
  const user = userPool.getCurrentUser();
  const client = generateClient()

  const [createPoint, setCreatePoint] = useState<boolean>(false)

  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [navigate, user])

  const onClick = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      features, lngLat, target
    } = event;
    const clickedFeature = features && features[0];

    if (clickedFeature?.properties?.id) {
      navigate({
        pathname: `/search/${clickedFeature.properties.id}`, search: `${createSearchParams({
          lat: `${lngLat.lat}`, lon: `${lngLat.lng}`, zoom: `${target.getZoom()}`,
        })}`,
      })
    }
  }, [navigate]);

  return (<>
    <div className="MapPage">
      <DefaultMap mapProps={{onClick}}>
        {  /* @ts-ignore */}
        <Source type="geojson" data={data}>
          <Layer {...dataLineLayer}/>
          <Layer {...dataLayer}/>
        </Source>
        <PointsOfInterest/>
      </DefaultMap>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{position: 'absolute', bottom: "4.5rem", left: "1rem"}}
        icon={<SpeedDialIcon/>}
      >
        <SpeedDialAction
          icon={<Add/>}
          tooltipTitle={"Add point of interest"}
          onClick={() => setCreatePoint(true)}
        />
      </SpeedDial>
    </div>
    <div className={"MapPageNavBar"}>
      <AppNavigator/>
    </div>
    {createPoint && <AddPoint
        usersPoints={[]}
        onClose={() => {
          setCreatePoint(false)
        }}
    />}
  </>);
}
