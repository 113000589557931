import React, {useEffect, useState} from "react"
import { Button, CardContent, Divider, Paper, Typography} from "@mui/material"
import {AddPoint} from "./AddPoint";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {generateClient} from "aws-amplify/api";
import { ListPointOfIntrestsQuery, PointOfIntrest} from "../../API";
import {listPointOfIntrests} from "../../graphql/queries";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {getPointLabel, ParkPoint, PointIcon} from "./PointMark";
import {deletePointOfIntrest} from "../../graphql/mutations";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import "./AddPointSection.css"

type AddPointSectionProps = {
  parkId: string
}
export const AddPointSection = ({parkId}: AddPointSectionProps) => {
  const { user } = useAuthenticator((context) => [context.user])
  const client = generateClient()
  const [points, setPoints] = useState<[] | PointOfIntrest[]>([])
  const [usersPoints, setUsersPoints] = useState<[] | PointOfIntrest[]>()

  const [createPoint, setCreatePoint] = useState<boolean>(false)


  useEffect(() => {
    const pointsPromise = client.graphql({
      query: listPointOfIntrests,
      authMode: user ? 'userPool' : 'iam',
      variables: {
        filter: {
          parkId: {
            eq: parkId
          }
        }
      }

    }) as Promise<GraphQLResult<ListPointOfIntrestsQuery>>

    pointsPromise.then((res) => {
      const items = res.data?.listPointOfIntrests?.items ?? []

      const allPoints = items.filter((item): item is PointOfIntrest => Boolean(item))

      console.log(allPoints)
      setPoints(allPoints)
      setUsersPoints(allPoints.filter((item) => item?.owner === user.username))
    })
  }, [user, parkId, createPoint])

  return (<>
    <Typography variant="h6" fontWeight={'bolder'} component="div" paddingTop={'3rem'}>Points of Interest</Typography>
    {points.map(point => {
        return <Paper
          key={point.id}
          elevation={2}
        >
          <CardContent>
            <div className="AddPointSection-header">
              <div className="AddPointSection-user">
                <PointIcon pointType={ParkPoint.parse(point.pointType)} />
                <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{getPointLabel(ParkPoint.parse(point.pointType))}</Typography>
              </div>
              {user.username === point.owner && (
                <Button variant="contained" color="error" onClick={async () => {
                  await client.graphql({
                    query: deletePointOfIntrest,
                    variables: {
                      input: {
                        id: point.id
                      },
                    },
                  })
                  window.location.reload(); // ew i need to fix this
                }}>
                  Delete
                </Button>
              )}
            </div>
            <div className="AddPointSection-info">
              <Typography variant="subtitle1" fontWeight={'bolder'} component="div" textTransform={'capitalize'} className="AddPointSection-info-capitalize-first-letter">{point.name}</Typography>
              <Typography variant="body1" component="div" className="AddPointSection-info-capitalize-first-letter" >{point.description}</Typography>
            </div>
            <Divider/>
            <div className="AddPointSection-buttons">
              <Button
                variant="contained"
                size={'large'}
                endIcon={<AssistantDirectionIcon/>}
                onClick={() => {
                  window.open(`https://maps.google.com?q=${point.latitude},${point.longitude}`)
                }}
              >
                Navigate
              </Button>
            </div>
          </CardContent>
        </Paper>
      }
    )}

    <Button
      variant="contained"
      size={'large'} onClick={() => setCreatePoint(true)}>Add
    </Button>
    <Divider/>
    {createPoint && <AddPoint usersPoints={usersPoints || []} onClose={() => {
      setCreatePoint(false)
    }}/>}
  </>)
}

