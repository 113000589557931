import React, {useMemo} from 'react';
import Map, {GeolocateControl} from 'react-map-gl';
import {getStyleJson} from "data/styleJson"
import {useSearchParams} from "react-router-dom";
import {MapProps} from "react-map-gl/src/exports-mapbox";

type Props = { mapProps?: MapProps } & React.PropsWithChildren

export function DefaultMap({mapProps, children}: Props) {
  const [params] = useSearchParams()

  const location = useMemo(() => {
    const lat = params.get("lat")
    const lon = params.get("lon")
    const zoom = params.get("zoom")

    if (lat && lon && zoom) {
      return {lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseFloat(zoom)}
    }
  }, [params])

  return (<Map
    terrain={{source: "basemaps-elevation-terrain-rgb-dem", exaggeration: 1.5}}
    mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
    initialViewState={location ? {
      longitude: location.lon, latitude: location.lat, zoom: location.zoom
    } : {
      longitude: 174.845749, latitude: -41.182734, zoom: 10
    }}
    style={{width: '100vw', height: '100vh'}}
    mapStyle={getStyleJson(process.env.REACT_APP_BASEMAPS_API_KEY || "")}
    onRender={(event) => event.target.resize()}
    interactiveLayerIds={['data']}
    {...mapProps}
  >
    <GeolocateControl
      position='top-right'
      trackUserLocation={true}
      showUserLocation={true}
      showAccuracyCircle={true}
      positionOptions={{enableHighAccuracy: true}}
      showUserHeading={true}
    />

    {children}

  </Map>)
}