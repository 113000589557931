import circle from "../../assets/mapIcons/circle_16dp_F3F3F3.png";
import parking from "../../assets/mapIcons/local_parking_16dp_F3F3F3.png";
import toilet from "../../assets/mapIcons/wc_16dp_F3F3F3.png";
import rubbish from "../../assets/mapIcons/delete_16dp_F3F3F3.png";
import dogWash from "../../assets/mapIcons/shower_16dp_F3F3F3.png";
import {useMap} from "react-map-gl";

export function useMapPointImages() {
  const {current: map} = useMap();


  map?.loadImage(
    parking,
    (error, image) => {
      if (error || !image) throw error;
      if(!map.hasImage('parking')) {
        map?.addImage('parking', image, {sdf: true});
      }
    })

  map?.loadImage(
    toilet,
    (error, image) => {
      if (error || !image) throw error;
      if(!map.hasImage('toilet')) {
        map?.addImage('toilet', image, {sdf: true});
      }
    })

  map?.loadImage(
    rubbish,
    (error, image) => {
      if (error || !image) throw error;
      if(!map.hasImage('rubbish')) {
        map?.addImage('rubbish', image, {sdf: true});
      }
    })

  map?.loadImage(
    dogWash,
    (error, image) => {
      if (error || !image) throw error;
      if(!map.hasImage('dog-wash')) {
        map?.addImage('dog-wash', image, {sdf: true});
      }
    })

  map?.loadImage(
    circle,
    (error, image) => {
      if (error || !image) throw error;
      if(!map.hasImage('circle')) {
        map?.addImage('circle', image, {sdf: true});
      }
    })
}