import {LocalParking, QuestionMark, Wc, Delete, Shower} from "@mui/icons-material";

import {Marker} from "react-map-gl";
import React from "react";
import {z} from "zod";

const SharedPoint = z.literal('TOILET')
export const ParkPoint = z.union([SharedPoint, z.literal('PARKING'), z.literal('RUBBISH')])
export const GenericPoint = z.union([SharedPoint, z.literal("DOG_WASH")])

export const Point = z.union([ParkPoint, GenericPoint])

export type ParkPointType = z.infer<typeof ParkPoint>
export type GenericPointType = z.infer<typeof GenericPoint>

export type PointType = z.infer<typeof Point>

type Props = {
  latitude: number, longitude: number, pointType: PointType, onDrag: (latitude: number, longitude: number) => void
  onClick: () => void
}

export function getPointLabel(pointType: PointType) {
  switch (pointType) {
    case "TOILET":
      return "Toilet"
    case "RUBBISH":
      return "Rubbish bin"
    case "PARKING":
      return "Parking"
    case "DOG_WASH":
      return "Dog wash"
  }
}

export function pointTypeToLabelOption(pointType: PointType) {
  return {label: getPointLabel(pointType), id: pointType}
}

export function getPointLabelOptions(genericPoint: boolean) {
  if(genericPoint) {
    return GenericPoint.options.map(option => ({
      label: getPointLabel(option.value), id: option.value
    }))
  }
  return ParkPoint.options.map(option => ({
    label: getPointLabel(option.value), id: option.value
  }))
}

export function PointIcon({pointType}: { pointType: PointType }) {
  switch (pointType) {
    case "PARKING":
      return <LocalParking style={{color: '#FFF', background: "#81838a", borderRadius: 90, padding: 2}}/>
    case "TOILET":
      return <Wc style={{color: '#FFF', background: "#7ba1eb", borderRadius: 90, padding: 2}}/>
    case "RUBBISH":
      return <Delete style={{color: '#FFF', background: "#efce90", borderRadius: 90, padding: 2}}/>
    case "DOG_WASH":
      return <Shower style={{color: '#FFF', background: "#7ba1eb", borderRadius: 90, padding: 2}}/>
    default:
      return <QuestionMark style={{color: '#FFF', background: "#cc4050", borderRadius: 90, padding: 2}}/>
  }
}

export function PointMark({latitude, longitude, pointType, onDrag, onClick}: Props) {
  return <Marker
    latitude={latitude}
    longitude={longitude}
    draggable={true}
    onDragEnd={(e) => onDrag(e.lngLat.lat, e.lngLat.lng)}
    onClick={(e) => {
      e.originalEvent.preventDefault()
      e.originalEvent.stopPropagation()
      onClick()
    }}
  >
    <PointIcon pointType={pointType}/>
  </Marker>
}